import api from '@/plugins/axios/api';

/**
 * @param {String} competenceId
 */
const copyCompetence = async ({ competenceId }) => {
  await api.post(`competences/${competenceId}/copy`);
};

export default copyCompetence;
