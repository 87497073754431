import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {Boolean} isPartOfRandomPool
 */
const gameStagesExport = async ({ gameId, isPartOfRandomPool }) => {
  const response = await api.get(`games/${gameId}/game-stages/export`, {
    params: { is_part_of_random_pool: isPartOfRandomPool ? 1 : 0 },
    responseType: 'blob',
  });

  return response;
};

export default gameStagesExport;
