<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("stages.list.title_random") }}
            </h5>
            <div class="table-heading__actions">
              <div class="table-heading__item">
                <BaseButton
                  v-if="hasAccess"
                  color="grayDark"
                  dark
                  @click="exportGameStageData"
                >
                  {{ $t("dashboard.export") }}
                  <v-icon right>
                    mdi-file-export
                  </v-icon>
                </BaseButton>
              </div>
              <div class="table-heading__item">
                <BaseButton @click="goToAddGameRandom">
                  {{ $t("dashboard.add") }}
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <div class="table-filters">
          <TableSearch
            is-full-text-search
            @input="fetchGameStages"
          />
        </div>
        <v-divider />
        <DraggableTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('stages.no_results')"
          :pagination="pagination"
          hide-default-footer
          :default-sort="sort"
        >
          <template v-slot:body="{ items }">
            <draggable
              :list="items"
              handle=".handle"
              ghost-class="moving-file"
              tag="tbody"
              @start="dragStart"
              @end="dragEnd(items)"
            >
              <tr
                v-for="item in items"
                :key="item.id"
              >
                <td>
                  <v-icon
                    small
                    class="handle"
                    :class="{ 'active': dragging }"
                  >
                    mdi-menu
                  </v-icon>
                </td>
                <td>{{ item.order + 1 }}</td>
                <td>{{ stageTitle(item) }}</td>
                <td>{{ item.type ? $t(`stages.types.${item.type.toLowerCase()}`) : "-" }}</td>
                <td>{{ item.stageable.data.competence ? item.stageable.data.competence.data.label : "-" }}</td>
                <td>{{ item.group ? item.group : "-" }}</td>
                <td>
                  <Actions
                    :game-id="$route.params.gameId"
                    :stage-id="item.id"
                    @handle-copy="copyGameStage"
                    @handle-delete="deleteGameStage"
                  />
                </td>
              </tr>
            </draggable>
          </template>
        </DraggableTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import alerts from '@/plugins/alerts';
import { strippedHtml } from '@/plugins/stringHelpers/strippedHtml';
import { GAME_RANDOM_ADD, GAME_STAGE_EXPORT } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED, HTTP_CONFLICT } from '@/plugins/axios/codes';
import { GAME_STAGE_DATA_EXPORT } from '@/router/permissions.names';
import { ASC } from '@/components/Table/sort.types';
import fetchGameStages from '@/components/Dashboard/GameStage/fetchGameStages';
import copyGameStage from '@/components/Dashboard/GameStage/copyGameStage';
import deleteGameStage from '@/components/Dashboard/GameStage/deleteGameStage';
import updateOrders from '@/components/Dashboard/GameStage/updateOrders';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseButton from '@/components/Form/BaseButton';
import DraggableTable from '@/components/Table/DraggableTable';
import TableSearch from '@/components/Table/TableSearch';
import Actions from '@/components/Dashboard/GameRandom/Actions';

export default {
  name: 'GameRandomList',
  components: {
    Preloader,
    BaseButton,
    DraggableTable,
    TableSearch,
    Actions,
    draggable,
  },
  data() {
    return {
      tableHeaders: [
        {
          value: 'draggable',
          sortable: false,
          align: 'center',
          width: '50px',
        },
        {
          value: 'order',
          text: this.$t('stages.list.order'),
          sortable: false,
        },
        {
          value: 'name',
          text: this.$t('stages.list.name'),
          sortable: false,
          width: '600px',
        },
        {
          value: 'type',
          text: this.$t('stages.list.type'),
          sortable: false,
        },
        {
          value: 'competence',
          text: this.$t('stages.list.competence'),
          sortable: false,
        },
        {
          value: 'group',
          text: this.$t('stages.list.group'),
          sortable: false,
        },
        {
          value: 'actions',
          sortable: false,
          align: 'right',
          width: '50px',
        },
      ],
      tableData: [],
      pagination: null,
      isLoading: false,
      isPreloadingData: false,
      dragging: false,
      sort: {
        column: 'order',
        direction: ASC,
      },
    };
  },
  computed: {
    ...mapGetters('session', ['hasPermission']),
    hasAccess() {
      return this.hasPermission(GAME_STAGE_DATA_EXPORT);
    },
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchGameStages();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    strippedHtml,
    goToAddGameRandom() {
      this.$router.push({ name: GAME_RANDOM_ADD });
    },
    dragStart() {
      this.dragging = true;
    },
    async dragEnd(items) {
      items.forEach((item, index) => {
        const elementIndex = this.tableData.findIndex(((stage) => stage.id === item.id));
        this.tableData[elementIndex].order = index;
      });
      const stages = this.tableData.map((item) => ({
        id: item.id,
        order: item.order,
      }));
      try {
        await updateOrders({ gameId: this.$route.params.gameId, stages });
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.dragging = false;
      }
    },
    setParams(params = {}) {
      return {
        gameId: this.$route.params.gameId,
        limit: 99999,
        page: 1,
        includes: ['stageable', 'stageable.competence'],
        sort: this.sort,
        filters: {
          is_part_of_random_pool: 1,
        },
        ...this.$route.query,
        ...params,
      };
    },
    async fetchGameStages(params) {
      try {
        this.isLoading = true;
        const response = await fetchGameStages(this.setParams(params));
        this.tableData = response.data;
        this.pagination = response.pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async copyGameStage({ gameId, stageId }) {
      try {
        this.isLoading = true;
        await copyGameStage({ gameId, stageId });
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_CONFLICT) {
          this.errorAlert({ text: e.response.data.message });

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
      await this.fetchGameStages();
    },
    async deleteGameStage({ gameId, stageId }) {
      try {
        this.isLoading = true;
        await deleteGameStage({ gameId, stageId });
        const elementIndex = this.tableData.findIndex(((stage) => stage.id === stageId));
        this.tableData.splice(elementIndex, 1);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_CONFLICT) {
          this.errorAlert({ text: e.response.data.message });

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
      await this.fetchGameStages();
    },
    stageTitle(stage) {
      return strippedHtml(stage.stageable.data.body);
    },
    async exportGameStageData() {
      try {
        const gameStageDataRoute = this.$router.resolve({
          name: GAME_STAGE_EXPORT,
          params: {
            gameId: this.$route.params.gameId,
          },
          query: {
            is_part_of_random_pool: true,
          },
        });
        window.open(gameStageDataRoute.href, '_blank');
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
  },
};
</script>
<style lang="scss">
.handle {
  cursor: grab;
  &.active {
    cursor: grabbing !important;
  }
}
.moving-file {
  opacity: 0.5;
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}
</style>
