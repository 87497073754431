<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <v-dialog
        v-model="innerValue"
        max-width="680"
      >
        <v-card>
          <v-card-title class="text-h5 pb-8">
            {{ $t("game.copy_game_to_client") }}:
          </v-card-title>
          <v-card-text>
            <BaseSelect
              v-model="formData.selectedClientId"
              rules="select"
              :items="clients"
              :label="$t('game.create.client')"
              :placeholder="$t('game.create.client_hint')"
              item-text="name"
              item-value="id"
              persistent-hint
              :loading="isLoading"
              :disabled="isLoading"
            />
            <BackendErrors :backend-errors="errors" />
          </v-card-text>
          <v-card-actions>
            <v-row
              class="mb-2 mx-1"
              justify="center"
            >
              <v-col cols="6">
                <BaseButton
                  outlined
                  @click="toggleModal"
                >
                  {{ $t("dashboard.back") }}
                </BaseButton>
              </v-col>
              <v-col cols="6">
                <BaseButton
                  type="submit"
                  :loading="isSending"
                  @click="submit"
                >
                  {{ $t("dashboard.copy") }}
                </BaseButton>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY, HTTP_CONFLICT } from '@/plugins/axios/codes';
import { ASC } from '@/components/Table/sort.types';
import fetchClients from '@/components/Dashboard/Client/fetchClients';
import copyGame from '@/components/Dashboard/Game/copyGame';
import BaseButton from '@/components/Form/BaseButton';
import BaseSelect from '@/components/Form/BaseSelect';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';

export default {
  name: 'CopyGameModal',
  components: {
    ValidationObserver,
    BaseButton,
    BaseSelect,
    BackendErrors,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    gameId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      clients: [],
      formData: {
        selectedClientId: null,
      },
      innerValue: null,
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
      if (newVal) {
        this.fetchClients();
      }
    },
  },
  methods: {
    ...alerts,
    async fetchClients() {
      try {
        this.isLoading = true;
        const { data } = await fetchClients({
          sort: {
            column: 'name',
            direction: ASC,
          },
          limit: 99999,
        });
        this.clients = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) return;
      try {
        this.errors = [];
        this.isSending = true;
        await copyGame(this.baseFormData());
        this.toggleModal();
        this.$notify.success(
          this.$t('notifications.confirm_modal.content'),
          this.$t('notifications.confirm_modal.title'),
        );
        this.$emit('refetch-data');
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_CONFLICT) {
          this.errorAlert({ text: e.response.data.message });

          return;
        }
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
    baseFormData() {
      return {
        gameId: this.gameId,
        clientId: this.formData.selectedClientId,
      };
    },
    toggleModal() {
      this.innerValue = !this.innerValue;
    },
  },
};
</script>
