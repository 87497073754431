import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {String} stageId
 */
const copyGameStage = async ({
  gameId,
  stageId,
}) => {
  await api.post(`games/${gameId}/game-stages/${stageId}/copy`);
};

export default copyGameStage;
