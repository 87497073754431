<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <div class="wrapper">
          <v-row>
            <v-col
              sm="12"
              lg="6"
              class="pr-lg-8"
            >
              <BaseInput
                v-model="formData.label"
                rules="min:3|max:255|required"
                :label="$t('competence.create.label')"
              />
            </v-col>
          </v-row>
        </div>
        <TranslationsMenu
          v-model="locale"
          :default-locale="clientDefaultLocale"
          :is-loading="isLoading"
          :has-changes="hasChanges"
          @change-locale="updateLocale"
        >
          <v-row>
            <v-col
              sm="12"
              lg="6"
              class="pr-lg-8"
            >
              <BaseInput
                v-model="formData.name"
                rules="min:3|max:255|required"
                :label="$t('competence.create.name')"
              />
              <BaseTextarea
                v-model="formData.description"
                rules="min:3|max:500|required"
                mode="eager"
                :label="$t('competence.create.description')"
              />
              <BaseExpandTextEditor
                v-model="formData.descriptionForCandidate"
                rules="min:3|max:2000"
                :label="$t('competence.create.description_for_candidate')"
              />
            </v-col>
            <v-col
              sm="12"
              lg="6"
            >
              <div v-if="formData.scoringScales.length">
                <div
                  v-for="(scoringScale, index) in formData.scoringScales"
                  :key="`scoring-scale-${index}`"
                  class="pb-4"
                >
                  <div class="title pb-4">
                    <span>{{ $t('competence.create.scale') }} {{ index + 1 }}: </span>
                    <span class="orange--text">
                      {{ `${scoringScale.percentage_from} - ${scoringScale.percentage_to}%` }}
                    </span>
                  </div>
                  <BaseRichTextEditor
                    v-model="scoringScale.description"
                    rules="min:3|max:800|required"
                    :label="$t('competence.create.scoring_scales')"
                    :vid="`${$t('competence.create.scoring_scales')}-${index}`"
                  />
                  <BaseExpandTextEditor
                    v-model="scoringScale.development_indications"
                    rules="min:3|max:2000"
                    :label="$t('competence.create.development_indications')"
                    :vid="`${$t('competence.create.development_indications')}-${index}`"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </TranslationsMenu>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseTextarea from '@/components/Form/BaseTextarea';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import BaseExpandTextEditor from '@/components/Form/BaseExpandTextEditor';
import BaseInput from '@/components/Form/BaseInput';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import TranslationsMenu from '@/components/Dashboard/Partials/Translations/TranslationsMenu';

export default {
  name: 'CompetenceForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseTextarea,
    BaseRichTextEditor,
    BaseExpandTextEditor,
    BaseInput,
    TranslationsMenu,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    competence: {
      type: Object,
      default: () => {},
    },
    scoringScales: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasChanges: false,
      formData: {
        name: '',
        description: '',
        descriptionForCandidate: '',
        scoringScales: [],
        label: '',
      },
    };
  },
  computed: {
    ...mapGetters('session', ['clientDefaultLocale']),
  },
  watch: {
    competence: {
      handler(newValue) {
        this.formData.scoringScales = this.scoringScales.map((scoringScale) => ({
          id: scoringScale.id,
          description: this.editMode ? this.setScoringScaleDescription(scoringScale.id) : '',
          development_indications: this.editMode ? this.setScoringScaleDevelopmentIndications(scoringScale.id) : '',
          percentage_from: scoringScale.percentage_from,
          percentage_to: scoringScale.percentage_to,
        }));
        if (this.editMode) {
          this.formData.name = newValue.name;
          this.formData.description = newValue.description;
          this.formData.descriptionForCandidate = newValue.description_for_candidate;
          this.formData.label = newValue.label;
        }
      },
    },
    formData: {
      handler() {
        if (this.editMode) {
          this.checkFormChanges();
        }
      },
      deep: true,
    },
  },
  created() {
    this.formData.scoringScales = this.scoringScales.map((scoringScale) => ({
      id: scoringScale.id,
      description: this.editMode ? this.setScoringScaleDescription(scoringScale.id) : '',
      development_indications: this.editMode ? this.setScoringScaleDevelopmentIndications(scoringScale.id) : '',
      percentage_from: scoringScale.percentage_from,
      percentage_to: scoringScale.percentage_to,
    }));
    if (this.editMode) {
      this.formData.name = this.competence.name;
      this.formData.description = this.competence.description;
      this.formData.descriptionForCandidate = this.competence.description_for_candidate;
      this.formData.label = this.competence.label;
    }
  },
  methods: {
    setScoringScaleDescription(scoringScaleId) {
      return this.competence.scoring_descriptions.data.find((score) => score.scoring_scale_id === scoringScaleId)?.description;
    },
    setScoringScaleDevelopmentIndications(scoringScaleId) {
      return this.competence.scoring_descriptions.data.find((score) => score.scoring_scale_id === scoringScaleId)?.development_indications;
    },
    cancel() {
      this.$emit('handle-cancel');
    },
    async checkFormChanges() {
      const { changed } = this.$refs.form.flags;
      this.hasChanges = changed;
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
      this.hasChanges = false;
    },
    baseFormData() {
      const baseFormData = {
        name: this.formData.name,
        description: this.formData.description,
        descriptionForCandidate: this.formData.descriptionForCandidate,
        label: this.formData.label,
        scoringDescriptions: this.formData.scoringScales.map((scoringScale) => ({
          scoring_scale_id: scoringScale.id,
          description: scoringScale.description,
          development_indications: scoringScale.development_indications,
        })),
      };
      if (!this.editMode) {
        return baseFormData;
      }

      return { ...baseFormData, competenceId: this.$route.params.competenceId };
    },
    async updateLocale(newLocale) {
      this.$emit('update-locale', newLocale);
    },
  },
};
</script>
