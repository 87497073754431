<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="vid"
    :name="label"
    :rules="rules"
  >
    <v-combobox
      v-model="innerValue"
      :menu-props="{ bottom: true, offsetY: true }"
      :error-messages="errors"
      :search-input.sync="search"
      outlined
      :label="label"
      v-bind="$attrs"
      v-on="$listeners"
      @update:error="$emit('validate-field', { label, isValid: valid })"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="search && search.length > 0">
                {{ $t("dashboard.not_found") }} "<strong>{{ search }}</strong>". {{ $t("dashboard.press") }} <kbd>enter</kbd> {{ $t("dashboard.to_add") }}
              </span>
              <span v-else>
                {{ $t("dashboard.no_data_available") }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseCombobox',
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: [String, Number, Object, Array],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    innerValue: null,
    search: null,
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
