<template>
  <div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <p class="headline black--text mt-4">
                {{ editMode ? $t("stages.edit") : $t("stages.add") }}
              </p>
              <v-divider class="mt-3 mb-4" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-row class="px-3 px-lg-6">
          <v-col
            sm="12"
            lg="10"
          >
            <component
              :is="stageComponent.name"
              v-if="currentStage"
              :key="currentStage.id"
              :stage="stage"
              :current-stage="currentStage"
              :random-groups="randomGroups"
              :edit-mode="editMode"
              :is-loading="isLoading"
              :is-sending="isSending"
              :backend-errors="errors"
              v-bind="stageComponent.params"
              @handle-submit="submit"
              @handle-cancel="returnToList"
            >
              <BaseSelect
                v-model="currentStage"
                rules="select"
                :items="stages"
                :label="$t('stages.type')"
                item-text="name"
                item-value="value"
                persistent-hint
                :loading="isLoading"
                :disabled="isLoading || editMode"
              />
            </component>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_NOT_FOUND, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { NOT_FOUND, GAME_STAGE_LIST } from '@/router/routes.names';
import {
  QUESTION_CLOSED,
  QUESTION_BEST_WORST,
  MESSAGE,
  VIDEO,
  IMAGE,
  RECORD,
  RANDOM,
} from '@/names/gameStageTypes.names';
import { ASC } from '@/components/Table/sort.types';
import addQuestion from '@/components/Dashboard/GameStage/addQuestion';
import editQuestion from '@/components/Dashboard/GameStage/editQuestion';
import ClosedQuestion from '@/components/Dashboard/GameStage/ClosedQuestion';
import BestWorstQuestion from '@/components/Dashboard/GameStage/BestWorstQuestion';
import GameMessage from '@/components/Dashboard/GameStage/GameMessage';
import GameRecord from '@/components/Dashboard/GameStage/GameRecord';
import VideoQuestion from '@/components/Dashboard/GameStage/Video';
import ImageQuestion from '@/components/Dashboard/GameStage/Image';
import RandomQuestion from '@/components/Dashboard/GameStage/Random';
import fetchGameStage from '@/components/Dashboard/GameStage/fetchGameStage';
import fetchRandomGroups from '@/components/Dashboard/GameStage/fetchRandomGroups';
import fetchGame from '@/components/Dashboard/Game/fetchGame';
import fetchCompetences from '@/components/Dashboard/Competence/fetchCompetences';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseSelect from '@/components/Form/BaseSelect';

export default {
  name: 'EditStage',
  components: {
    Preloader,
    BaseSelect,
    ClosedQuestion,
    BestWorstQuestion,
    GameMessage,
    GameRecord,
    VideoQuestion,
    ImageQuestion,
    RandomQuestion,
  },
  data() {
    return {
      currentStage: 'QUESTION_CLOSED',
      competences: null,
      stage: null,
      randomGroups: [],
      stages: [
        { name: this.$t('stages.types.question_closed'), value: QUESTION_CLOSED },
        { name: this.$t('stages.types.question_best_worst'), value: QUESTION_BEST_WORST },
        { name: this.$t('stages.types.message'), value: MESSAGE },
        { name: this.$t('stages.types.video'), value: VIDEO },
        { name: this.$t('stages.types.image'), value: IMAGE },
        { name: this.$t('stages.types.record'), value: RECORD },
        { name: this.$t('stages.types.random'), value: RANDOM },
      ],
      editMode: false,
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  computed: {
    stageComponent() {
      switch (true) {
        case this.currentStage === QUESTION_CLOSED:
          return {
            name: 'ClosedQuestion',
            params: {
              competences: this.competences,
            },
          };
        case this.currentStage === QUESTION_BEST_WORST:
          return {
            name: 'BestWorstQuestion',
            params: {
              competences: this.competences,
            },
          };
        case this.currentStage === MESSAGE:
          return {
            name: 'GameMessage',
          };
        case this.currentStage === VIDEO:
          return {
            name: 'VideoQuestion',
          };
        case this.currentStage === IMAGE:
          return {
            name: 'ImageQuestion',
          };
        case this.currentStage === RECORD:
          return {
            name: 'GameRecord',
          };
        case this.currentStage === RANDOM:
          return {
            name: 'RandomQuestion',
          };
        default:
          throw new Error('Invalid stage type');
      }
    },
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      this.isLoading = true;
      const game = await fetchGame({
        gameId: this.$route.params.gameId,
        includes: ['client', 'options'],
      });
      const competences = await fetchCompetences({
        limit: 99999,
        sort: {
          column: 'name',
          direction: ASC,
        },
        filters: {
          client_id: game.client.data.id,
        },
      });
      this.competences = competences.data;
      this.randomGroups = await fetchRandomGroups({
        gameId: this.$route.params.gameId,
      });
      if (this.editMode) {
        this.stage = await fetchGameStage({
          gameId: this.$route.params.gameId,
          stageId: this.$route.params.stageId,
          includes: ['stageable', 'stageable.competence', 'stageable.answers.feedback', 'stageable.answers', 'stageable.feedback'],
        });
        this.currentStage = this.stage.type;
      }
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: GAME_STAGE_LIST });
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        this.editMode ? await editQuestion(payload) : await addQuestion(payload);
        this.$notify.save.success();
        this.returnToList();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
