<template>
  <v-menu
    v-model="isMenuOpen"
    offset-y
    origin="center center"
    elevation="2"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-card class="actions-list">
      <v-list-item
        :to="{ name: gameEditRoute, params: { gameId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("game.edit_game") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: gameStageListRoute, params: { gameId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("game.edit_stages") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: gameRandomStageListRoute, params: { gameId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-shuffle</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("game.edit_random_stages") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: badgeListRoute, params: { gameId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-crown</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("game.edit_badges") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: gamePageListRoute, params: { gameId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-file-document-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("game.edit_pages") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="hasAccessToCopyingGames"
        @click.stop="openCopyGameModal"
      >
        <v-list-item-icon>
          <v-icon>mdi-content-copy</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.copy") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click.stop="openTagsModal">
        <v-list-item-icon>
          <v-icon>mdi-tag</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("game.manage_tags") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="handleDelete">
        <v-list-item-icon>
          <v-icon>mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.remove") }}</v-list-item-title>
      </v-list-item>
    </v-card>
    <CopyGameModal
      :key="gameId"
      v-model="isModalOpen"
      :game-id="gameId"
      @refetch-data="refetchData"
    />
    <AssignTagsModal
      v-model="isTagsModalOpen"
      :game-id="gameId"
      @refetch-data="refetchData"
    />
  </v-menu>
</template>
<script>
import alerts from '@/plugins/alerts';
import { mapGetters } from 'vuex';
import {
  GAME_BADGE_LIST,
  GAME_EDIT,
  GAME_PAGE_LIST,
  GAME_STAGE_LIST,
  GAME_RANDOM_LIST,
} from '@/router/routes.names';
import { GAME_COPY } from '@/router/permissions.names';
import CopyGameModal from '@/components/Dashboard/Game/CopyGameModal';
import AssignTagsModal from '@/components/Dashboard/Game/AssignTagsModal';

export default {
  name: 'GameActions',
  components: {
    AssignTagsModal,
    CopyGameModal,
  },
  props: {
    gameId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      isModalOpen: false,
      isTagsModalOpen: false,
    };
  },
  computed: {
    ...mapGetters('session', ['permissions']),
    hasAccessToCopyingGames() {
      return this.permissions.includes(GAME_COPY);
    },
    badgeListRoute() {
      return GAME_BADGE_LIST;
    },
    gameEditRoute() {
      return GAME_EDIT;
    },
    gamePageListRoute() {
      return GAME_PAGE_LIST;
    },
    gameStageListRoute() {
      return GAME_STAGE_LIST;
    },
    gameRandomStageListRoute() {
      return GAME_RANDOM_LIST;
    },
  },
  methods: {
    ...alerts,
    async handleDelete() {
      const { gameId } = this;
      if (await this.removeAlert()) {
        this.$emit('handle-delete', { gameId });
      }
    },
    openCopyGameModal() {
      this.isModalOpen = true;
      this.closeMenu();
    },
    openTagsModal() {
      this.isTagsModalOpen = true;
      this.closeMenu();
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    refetchData() {
      this.$emit('refetch-data');
    },
  },
};
</script>
