<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            lg="6"
          >
            <slot />
            <BaseInput
              v-model="formData.title"
              rules="min:3|max:255|required"
              :label="$t('stages.random.title')"
            />
            <BaseSelect
              v-model="formData.group"
              rules="select"
              :items="randomGroups"
              :label="$t('stages.random.group')"
              :placeholder="$t('stages.random.group_hint')"
              item-text="label"
              item-value="value"
              persistent-hint
              searchable
              :loading="isLoading"
              :disabled="isLoading"
            />
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseInput from '@/components/Form/BaseInput';
import BaseSelect from '@/components/Form/BaseSelect';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';

export default {
  name: 'RandomQuestion',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseInput,
    BaseSelect,
  },
  props: {
    backendErrors: {
      type: Array,
      default: () => [],
    },
    currentStage: {
      type: String,
      required: true,
    },
    stage: {
      type: Object,
      default: () => {},
    },
    randomGroups: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        title: '',
        group: '',
      },
    };
  },
  created() {
    if (this.editMode) {
      this.formData.title = this.stage.stageable.data.title;
      this.formData.group = this.stage.group;
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const formData = new FormData();
      const { gameId, stageId } = this.$route.params;
      formData.set('type', this.currentStage);
      formData.set('title', this.formData.title);
      formData.set('group', this.formData.group);
      if (this.editMode) {
        formData.set('_method', 'PUT');

        return { gameId, stageId, params: formData };
      }

      return { gameId, params: formData };
    },
  },
};
</script>
