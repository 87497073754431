export default {
  title: 'Badges',
  search: 'Search',
  name: 'Name',
  stage_number: 'Step #',
  type: 'Step type',
  content: 'Content',
  created_at: 'Created at',
  no_results: 'No games found',
  types: {
    question_best_worst: 'Multiselect (up, down)',
    question_closed: 'Singleselect',
    message: 'Message',
    open: 'Open ended',
    video: 'Video',
    image: 'Graphics',
    random: 'Random',
  },
  add: 'Add a badge',
  edit: 'Edit a badge',
  create: {
    name: 'Name',
    description: 'Content',
    icon: 'Icon',
    image: 'Photo',
    video_mp4: 'Video MP4',
    video_webm: 'Video WEBM',
    stage: 'Step',
    stage_body: 'Step content',
    stage_answer: 'Answer',
    add_to_answer: 'Assign to an answer',
    select_add_to_answer: 'Select the badge to be awarded after selecting the desired answer',
    tip_one: 'You can add 1 badge to 1 question',
    tip_two: 'You can create up to 6 badges for the game',
  },
};
