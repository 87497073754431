<template>
  <v-menu
    offset-y
    elevation="2"
    transition="fade-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-card class="actions-list">
      <v-list-item
        :to="{ name: gameRandomEditRoute, params: { gameId, stageId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.edit") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="hasAccessToCopyingGameStages"
        @click.stop="copyGameStage"
      >
        <v-list-item-icon>
          <v-icon>mdi-content-copy</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.copy") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="handleDelete">
        <v-list-item-icon>
          <v-icon>mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.remove") }}</v-list-item-title>
      </v-list-item>
    </v-card>
  </v-menu>
</template>
<script>
import alerts from '@/plugins/alerts';
import { mapGetters } from 'vuex';
import { GAME_STAGE_COPY } from '@/router/permissions.names';
import { GAME_RANDOM_EDIT } from '@/router/routes.names';

export default {
  name: 'GameRandomActions',
  props: {
    gameId: {
      type: String,
      required: true,
    },
    stageId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('session', ['permissions']),
    hasAccessToCopyingGameStages() {
      return this.permissions.includes(GAME_STAGE_COPY);
    },
    gameRandomEditRoute() {
      return GAME_RANDOM_EDIT;
    },
  },
  methods: {
    ...alerts,
    async copyGameStage() {
      const { gameId, stageId } = this;
      if (await this.confirmAlert()) {
        this.$emit('handle-copy', { gameId, stageId });
      }
    },
    async handleDelete() {
      const { gameId, stageId } = this;
      if (await this.removeAlert()) {
        this.$emit('handle-delete', { gameId, stageId });
      }
    },
  },
};
</script>
