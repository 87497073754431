import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 */
const fetchRandomGroups = async ({
  gameId,
}) => {
  const { data: { data } } = await api.get(`games/${gameId}/random-groups`);

  return data;
};

export default fetchRandomGroups;
