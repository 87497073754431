export default {
  note: 'Uwaga',
  cancel: 'Anuluj',
  close: 'Zamknij',
  back: 'Wstecz',
  accept: 'Zatwierdź',
  confirm: 'Potwierdź',
  add: 'Dodaj',
  save: 'Zapisz',
  edit: 'Edytuj',
  remove: 'Usuń',
  clone: 'Duplikuj',
  copy: 'Kopiuj',
  copied: 'Skopiowano',
  export: 'Eksport',
  export_basic: 'Podstawowy',
  export_extended: 'Rozszerzony',
  upload_image: 'Wgraj zdjęcie',
  upload_video: 'Wgraj video',
  upload_image_details: 'Wgraj grafikę w formacie: %{exts}',
  upload_video_details: 'Wgraj video (%{exts}, max. %{maxSize} MB)',
  upload_favicon_ext: 'Wgraj ikonę w formacie: .ico',
  tags: 'Znaczniki',
  no_data_available: 'Brak danych',
  not_found: 'Nie znaleziono',
  press: 'Naciśnij',
  to_add: 'aby dodać',
  download: 'pobierz',
  of: 'z',
  table: {
    loading: 'Trwa ładowanie...',
    pagination_results: 'Wyników na stronę',
  },
  topbar: {
    logout: 'Wyloguj się',
  },
  sidebar: {
    management: 'Zarządzanie rekrutacjami',
    admin: 'Administracja',
  },
  locale_types: {
    polish: 'Polski',
    english: 'English',
  },
  modal: {
    error: {
      title: 'Wystąpił błąd',
      content: 'Spróbuj ponownie, a jeśli błąd będzie się powtarzał skontaktuj się z obsługą techniczną.',
      button: 'Zamknij',
    },
    cancel: {
      title: 'Wszystkie zmiany zostaną utracone. Czy chcesz kontynuować?',
      confirm: 'Tak',
      cancel: 'Nie',
    },
    confirm: {
      title: 'Czy chcesz kontynuować?',
      confirm: 'Tak',
      cancel: 'Nie',
    },
    invite: {
      title: 'Czy wysłać zaproszenia?',
      content: 'W formularzu wprowadzono poprawnie <strong>%{quantity}</strong> %{plural} e-mail.',
      content_for_multiple_candidates: 'Na liście adresów znajduje się <strong>%{existingCandidatesLength}</strong> Kandydatów, którzy ukończyli już to narzędzie – ponowne zaproszenie zresetuje wyniki, a Kandydaci będą mogli ponownie je rozpocząć. Dotyczy:',
      content_for_single_candidate: 'Na liście Kandydatów znajduje się <strong>1</strong> Kandydat, który ukończył już to narzędzie - ponowne zaproszenie zresetuje wynik, a Kandydat będzie mógł je ponownie rozpocząć. Dotyczy:',
      confirm: 'Zaproś',
      cancel: 'Wstecz',
    },
    change_stage_or_status: {
      title_for_multiple_candidates: 'Czy wysłać zaproszenia?',
      title_for_single_candidate: 'Czy wysłać zaproszenie?',
      content_for_multiple_candidates: 'Na liście adresów znajduje się <strong>%{existingCandidatesLength}</strong> Kandydatów, którzy ukończyli już to narzędzie – ponowne zaproszenie zresetuje wyniki, a Kandydaci będą mogli ponownie je rozpocząć. Dotyczy:',
      content_for_single_candidate: 'Wybrany Kandydat ukończył już to narzędzie – ponowne zaproszenie zresetuje wyniki, a Kandydat posiadający adres email <strong>%{email}</strong> będzie mógł ponownie je rozpocząć.',
      confirm: 'Zaproś',
      cancel: 'Wstecz',
    },
    recruitment_edit: {
      title: 'Wygenerowano link do procesu rekrutacyjnego',
      content: 'Udostępnij go kandydatom aby umożliwić uczestnictwo w procesie rekrutacyjnym <br><br> %{registrationUrl}',
      confirm: 'Zamknij',
    },
    remove: {
      title: 'Czy na pewno chcesz usunąć?',
      content: 'Usunięcie jest operacją nieodwracalną.',
      confirm: 'Usuń',
      cancel: 'Wstecz',
    },
  },
};
